export const paths = {
    'homePage': '/',
    'projects': '/projects',
    'technologies': '/technologies',
    'about': '/about',
    'ClikSync': '/projects/cliksync',
    'UsaHandyman': '/projects/usahandyman',
    'SmartPay': '/projects/smartpay',
    'AiChatBot': '/projects/aichatbot',
    'undefined': '*'
}