import classes from "../styles/components/technologies.module.scss";
import {Col, Row} from "react-bootstrap";
import html from "../images/technologies/html.png";
import css from "../images/technologies/css.png";
import bootstrap from "../images/technologies/bootstrap.png";
import js from "../images/technologies/js.png";
import sass from "../images/technologies/sass.png";
import react from "../images/technologies/react.png";
import redux from "../images/technologies/redux.png";
import nodejs from "../images/technologies/nodejs.png";
import express from "../images/technologies/express.png";
import mongodb from "../images/technologies/mongodb.png";
import redis from "../images/technologies/redis.webp";

function Technologies() {
    return(
        <Row className={classes.technologies}>
            <Col vertical_line={classes.vertical_line_right}>
                <h5>Front-end</h5>
                <Row>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                        <img src={html} className={classes.technologies_icons}/>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                        <img src={css} className={classes.technologies_icons}/>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                        <img src={bootstrap} className={classes.technologies_icons}/>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                        <img src={js} className={classes.technologies_icons}/>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                        <img src={sass} className={classes.technologies_icons}/>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                        <img src={react} className={classes.technologies_icons}/>
                    </Col>
                    <Col>
                        <img src={redux} className={classes.technologies_icons}/>
                    </Col>
                </Row>
            </Col>
            <Col vertical_line={classes.vertical_line_left}>
                <h5>Back-end</h5>
                <Row>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                        <img src={nodejs} className={classes.technologies_icons}/>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                        <img src={express} className={classes.technologies_icons}/>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                        <img src={mongodb} className={classes.technologies_icons}/>
                    </Col>
                    <Col>
                        <img src={redis} className={classes.technologies_icons}/>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default Technologies;