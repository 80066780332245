import {Col, Container, Row} from "react-bootstrap";
import elements from "../../styles/settings/elements.module.scss";
import AIChatBot from "../../components/projects/AIChatBot";
import SmartPay from "../../components/projects/SmartPay";
import UsaHandyman from "../../components/projects/UsaHandyman";
import ClikSync from "../../components/projects/ClikSync";

function ProjectsPage(){
    return(
        <Container className={elements.container}>
            <p>My projects (click to see more details)</p>
            {/*<a href="#"><button className={projectsElements.code_button}><AiFillGithub className={projectsElements.code_icon}/>Code</button></a>*/}

            <h3 className={elements.section}>Projects</h3>
            <hr className={elements.section_hr}/>

            <Row>
                <ClikSync/>
                <AIChatBot/>
                <SmartPay/>
                <UsaHandyman/>
                {/*<SPCredit_Landing/>*/}
            </Row>
        </Container>
    );
}

export default ProjectsPage;