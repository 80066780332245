import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/settings/app.scss';

import {Route, Routes} from "react-router-dom";
import {paths} from "./storage/paths";
import HomePage from "./pageContainers/pages/HomePage";
import ProjectsPage from "./pageContainers/pages/ProjectsPage";
import TechnologiesPage from "./pageContainers/pages/TechnologiesPage";
import AboutPage from "./pageContainers/pages/AboutPage";
import PageContainer from "./pageContainers/PageContainer";
import SPCreditLandingPage from "./pageContainers/docsPages/SPCreditLandingPage";
import UsaHandymanPage from "./pageContainers/docsPages/UsaHandymanPage";
import SmartPayPage from "./pageContainers/docsPages/SmartPayPage";
import AIChatBotPage from "./pageContainers/docsPages/AIChatBotPage";
import ClikSync from "./components/projects/ClikSync";
import ClikSyncPage from "./pageContainers/docsPages/ClikSyncPage";

function App() {
  return (
      <Routes>
        <Route path={paths.homePage} element={
            <PageContainer>
                <HomePage />
            </PageContainer>
        } />
        <Route path={paths.projects} element={
            <PageContainer>
                <ProjectsPage />
            </PageContainer>
        } />
        <Route path={paths.technologies} element={
            <PageContainer>
                <TechnologiesPage />
            </PageContainer>
        } />
        <Route path={paths.about} element={
            <PageContainer>
                <AboutPage />
            </PageContainer>
        } />

          <Route path={paths.ClikSync} element={
              <PageContainer>
                  <ClikSyncPage />
              </PageContainer>
          } />

          <Route path={paths.UsaHandyman} element={
              <PageContainer>
                  <UsaHandymanPage />
              </PageContainer>
          } />

          <Route path={paths.SmartPay} element={
              <PageContainer>
                  <SmartPayPage />
              </PageContainer>
          } />

          <Route path={paths.AiChatBot} element={
              <PageContainer>
                  <AIChatBotPage />
              </PageContainer>
          } />

        <Route path={paths.undefined} element={
            <PageContainer>
                <HomePage />
            </PageContainer>
        } />
      </Routes>
  );
}

export default App;
