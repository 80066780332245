import elements from "../../styles/settings/elements.module.scss";
import {Container} from "react-bootstrap";
import Jobs from "../../components/Jobs";

function AboutPage(){
    return(
        <Container className={`${elements.container} ${elements.about_page_container}`}>
            <h3 className={elements.section}>About me</h3>
            <hr className={elements.section_hr}/>

            <p>My name is Max. I'm 18 yo and I'm fullstack web developer. My main programming language is JavaScript and I use it in the whole development of my applications. For frontend development I use React and for backend - Node.JS. I speak English and Russian.</p>

            {/*<Jobs/>*/}
        </Container>
    );
}

export default AboutPage;