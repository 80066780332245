import classes from "../../styles/pages/projects.module.scss";
import {BsGlobe} from "react-icons/bs";
import {Col, Container, Row} from "react-bootstrap";
import html from "../../images/technologies/html.png";
import css from "../../images/technologies/css.png";
import bootstrap from "../../images/technologies/bootstrap.png";
import js from "../../images/technologies/js.png";
import sass from "../../images/technologies/sass.png";
import react from "../../images/technologies/react.png";
import nodejs from "../../images/technologies/nodejs.png";
import express from "../../images/technologies/express.png";
import handymanMainPage from "../../images/projects/handyman_mainpage.png";
import handymanMainPageContact from "../../images/projects/handyman_mainpage_contact.png";
import handymanMainPageIcons from "../../images/projects/handyman_mainpage_icons.png";
import handymanMainPageFooter from "../../images/projects/handyman_mainpage_footer.png";
import handymanServices from "../../images/projects/handyman_services.png";
import handymanPortfolio from "../../images/projects/handyman_portfolio.png";
import handymanAbout from "../../images/projects/handyman_about.png";
import handymanService from "../../images/projects/handyman_service.png";
import StatusDevelopmentButton from "../../components/buttons/StatusDevelopmentButton";
import StatusWorkButton from "../../components/buttons/StatusWorkButton";
import StatusCompletedButton from "../../components/buttons/StatusCompletedButton";

function UsaHandymanPage() {
    return(
        <>
            <div className={`${classes.imageBlock} ${classes.imageBlock}`}>
                <div className={classes.imageBlockContent}>
                    <h2 className={classes.pageTitle}>Usa-Handyman</h2>
                    <p className={classes.pageDescription}>Website For a Remodeling Company</p>
                    <a href="https://usa-handyman.com" target="_blank" className={classes.link}><button className={classes.visitWebsite}><BsGlobe className={classes.visitIcon}/> Visit Website</button></a>
                </div>
            </div>

            <Container>
                <h3 className={classes.bigTitle}>Technologies</h3>
                <div className={classes.technologies_icons_row}>
                    <div className={classes.technologies_icons_col}>
                        <img src={html} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={css} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={bootstrap} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={js} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={sass} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={react} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={nodejs} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={express} className={classes.technologies_icons}/>
                    </div>
                </div>

                <h3 className={classes.bigTitle}>About</h3>
                <p>Website with portfolio and a list of services remodeling company provides to its' clients. Also, client can contact a company by scheduling an appointment or sending some photos of a project to get an estimate. Moreover, website is translated to 3 languages (EN, RU, ES) and shows a language due to user's default language.</p>
                <StatusCompletedButton/>
                &#8195;
                &#8195;
                <StatusWorkButton/>

                <h3 className={classes.bigTitle}>Review</h3>
                <Row>
                    <h5 className={classes.smallTitle}>Home Page</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>There is a header on the website with logo and navigation menu. Website has 4 main pages listed in the navigation menu.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={handymanMainPage} className={classes.reviewImage}/>
                    </Col>
                </Row>
                <Row>
                    <h5 className={classes.smallTitle}>Contact</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>There is a "Contact Us" form on a website, so that client can schedule and appointment or get an estimate sending some photos of his project.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={handymanMainPageContact} className={classes.reviewImage}/>
                    </Col>
                </Row>
                <Row>
                    <h5 className={classes.smallTitle}>Content</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>There is a block of advantages and most popular services on the website visually supported with colorful pictures which contribute to the comfortable UI.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={handymanMainPageIcons} className={classes.reviewImage}/>
                    </Col>
                </Row>
                <Row>
                    <h5 className={classes.smallTitle}>Footer</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>At the end of the Home Page, there are some photos from portfolio and footer with more information about the company.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={handymanMainPageFooter} className={classes.reviewImage}/>
                    </Col>
                </Row>
                <Row>
                    <h5 className={classes.smallTitle}>Services Page</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>There is a list of services company has sorted in categories to make it more comfortable for a user to select a suitable for him. Services are clickable and they lead to separate pages for every service.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={handymanServices} className={classes.reviewImage}/>
                    </Col>
                </Row>
                <Row>
                    <h5 className={classes.smallTitle}>Service Page</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>Separate page for every service with description.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={handymanService} className={classes.reviewImage}/>
                    </Col>
                </Row>
                <Row>
                    <h5 className={classes.smallTitle}>Portfolio Page</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>Company's portfolio with work examples divided into different categories.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={handymanPortfolio} className={classes.reviewImage}/>
                    </Col>
                </Row>
                <Row>
                    <h5 className={classes.smallTitle}>About Page</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>The rest of information about the company.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={handymanAbout} className={classes.reviewImage}/>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default UsaHandymanPage;