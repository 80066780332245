import {Col, Container, Row} from "react-bootstrap";
import classes from "../../styles/pages/projects.module.scss";
import html from "../../images/technologies/html.png";
import css from "../../images/technologies/css.png";
import js from "../../images/technologies/js.png";
import sass from "../../images/technologies/sass.png";
import react from "../../images/technologies/react.png";
import redux from "../../images/technologies/redux.png";
import nodejs from "../../images/technologies/nodejs.png";
import express from "../../images/technologies/express.png";
import mongodb from "../../images/technologies/mongodb.png";
import redis from "../../images/technologies/redis.webp";
import {BsGlobe} from "react-icons/bs";
import StatusDevelopmentButton from "../../components/buttons/StatusDevelopmentButton";
import StatusWorkButton from "../../components/buttons/StatusWorkButton";

function SPCreditLandingPage() {
    return(
        <>
            <div className={`${classes.imageBlock} ${classes.imageBlock}`}>
                <div className={classes.imageBlockContent}>
                    <h2 className={classes.pageTitle}>ClikSync</h2>
                    <p className={classes.pageDescription}>Website to connect brands with influencers</p>
                    <a href="https://cliksync.com" target="_blank" className={classes.link}><button className={classes.visitWebsite}><BsGlobe className={classes.visitIcon}/> Visit Website</button></a>
                </div>
            </div>

            <Container>
                <h3 className={classes.bigTitle}>Technologies</h3>
                <div className={classes.technologies_icons_row}>
                    <div className={classes.technologies_icons_col}>
                        <img src={html} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={css} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={js} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={sass} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={react} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={redux} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={nodejs} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={express} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={mongodb} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={redis} className={classes.technologies_icons}/>
                    </div>
                </div>

                <h3 className={classes.bigTitle}>About</h3>
                <p>Cliksync connects brands with influencers in the most comfortable way. Find the perfect partnership and grow together.</p>
                <StatusDevelopmentButton/>
                &#8195;
                &#8195;
                <StatusWorkButton/>

                {/*<h3 className={classes.bigTitle}>Review</h3>*/}
            </Container>
        </>
    );
}

export default SPCreditLandingPage;